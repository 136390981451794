<template>
    <div style="text-align: left;background-color: #FFFFFF">
            <div class="pageCenter setupForm-container">
                <el-form :model="setupForm" :rules="setupFormRules" ref="setupForm" label-width="100px">
                    <el-form-item label="登录账号：" prop="userDcodeUsername">
                        <el-input v-model="setupForm.userDcodeUsername" autocomplete="off" placeholder="设置登录账号：张三"></el-input>
                    </el-form-item>
                    <el-form-item label="用户ID：" prop="id">
                        <el-input v-model="setupForm.id" autocomplete="off" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="" prop="avata">
                        <upload-image @uploadImageBack="uploadImageBack" :avata="setupForm.avata">
                            <img v-if="setupForm.avata" :src="this.$oucy.ossUrl+setupForm.avata" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </upload-image>
                    </el-form-item>
                    <el-form-item label="绑定手机：" prop="phoneNumber">
                        <el-input v-model="setupForm.phoneNumber" autocomplete="off" placeholder="选择常用手机号，手机号码可用于登录操作。"></el-input>
                    </el-form-item>
                    <el-form-item label="短信验证码：" prop="phoneCode">
                        <el-input v-model="setupForm.phoneCode" autocomplete="off" placeholder="请输入短信验证码" :disabled="!(isNeedPhoneCode())">
                            <el-button @click="getAccSetupPhoneCode" :disabled="!showGetCode" type="text"
                                       style="padding-right:10px;color:#0366C3;" slot="suffix">
                                <template v-if="isNeedPhoneCode()">
                                    <span v-if="showGetCode">获取验证码</span>
                                    <span v-else class="count">{{ codeTime }} s</span>
                                </template>
                            </el-button>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="绑定微信：" prop="unionId">

                        <!--初始状态-->
                        <div v-show="!reBindWechat">

                            <!--已绑定-->
                            <div v-show="localUserInfo && (!!localUserInfo.userWechatUnicode)" class="df-ai-c">
                              已绑定微信<el-avatar size="small" :src="$oucy.ossUrl+localUserInfo.detailWechatAvata" v-if="localUserInfo && localUserInfo.detailWechatAvata"></el-avatar>(<el-button @click="reScanHandle" type="text">绑定其它微信账号</el-button>)
                            </div>

                            <!--未绑定-->
                            <div v-show="localUserInfo && (!localUserInfo.userWechatUnicode)">

                                <!--未扫码-->
                                <div v-show="!scanUserEntity">
                                    <div ref="unionIdQrCodeUrl_1"></div>
                                    <div>
                                      请使用微信扫描二维码进行账号绑定
                                    </div>
                                </div>

                                <!--已扫码-->
                                <div v-if="!!scanUserEntity">
                                    <div style="text-align: center;width: 146px;">
                                      <img :src="this.$oucy.ossUrl+scanUserEntity.detailAvatar" style="width: 146px;height: 146px;border-radius: 3px;">
                                      <div style="color: #1aad19">({{scanUserEntity.detailNickname}})</div>
                                    </div>
                                    <div v-if="setupForm.unionId" style="color: #cccccc">
                                      <i class="el-icon-success" style="color: #1aad19;font-size: 20px;"></i>
                                      操作成功！ 你也可以 <el-button @click="reScanHandle" type="text">重新扫码</el-button>
                                    </div>
                                    <div v-else style="color: #cccccc">
                                      扫码成功，请按手机提示进行操作，你也可以 <el-button @click="reScanHandle" type="text">重新扫码</el-button>
                                    </div>
                                </div>

                            </div>

                        </div>

                        <!--重置状态-->
                        <div v-show="!!reBindWechat">
                            <div v-show="!scanUserEntity">
                                <div ref="unionIdQrCodeUrl_2"></div>
                                <div>
                                    请使用微信扫描二维码进行账号绑定
                                    <span v-if="localUserInfo && (!!localUserInfo.userWechatUnicode)">
                                        或 <el-button @click="cancelScanHandle" type="text">取消操作</el-button>
                                    </span>
                                </div>
                            </div>
                            <div v-show="!!scanUserEntity">
                                <div v-if="!!scanUserEntity" style="text-align: center;width: 146px;">
                                    <img :src="this.$oucy.ossUrl+scanUserEntity.detailAvatar" style="width: 146px;height: 146px;border-radius: 3px;">
                                    <div style="color: #1aad19">({{scanUserEntity.detailNickname}})</div>
                                </div>
                                <div v-if="setupForm.unionId" style="color: #cccccc">
                                    <i class="el-icon-success" style="color: #1aad19;font-size: 20px;"></i>
                                    操作成功！ 你也可以 <el-button @click="reScanHandle" type="text">重新扫码</el-button>
                                    <span v-if="localUserInfo && (!!localUserInfo.userWechatUnicode)">
                                        或 <el-button @click="cancelScanHandle" type="text">取消操作</el-button> 。
                                    </span>
                                </div>
                                <div v-else style="color: #cccccc">
                                    扫码成功，请按手机提示进行操作，你也可以 <el-button @click="reScanHandle" type="text">重新扫码</el-button>
                                    <span v-if="localUserInfo && (!!localUserInfo.userWechatUnicode)">
                                        或 <el-button @click="cancelScanHandle" type="text">取消操作</el-button> 。
                                    </span>
                                </div>
                            </div>
                        </div>

                    </el-form-item>
                    <el-form-item label="注册时间：" prop="createTime">
                        <el-input v-model="setupForm.createTime" autocomplete="off" disabled></el-input>
                    </el-form-item>
                    <el-form-item>
                        <div class="bottom_button">
                            <el-button @click="keywordFiltr('submitSetup')" type="primary">保存</el-button>
                            <el-button @click="$oucy.replace('/acc/index', null)">取消</el-button>
                        </div>
                    </el-form-item>
                </el-form>
            </div>
    </div>
</template>

<script>
    import oss from "@/util/oss.js"
    import QRCode from 'qrcodejs2'
    import oucy from "../../../util/oucyUtil";
    import uploadImage from "@/components/uploadImage";
    import {userMaterial,userAuth } from "@/service"

    export default {
        name: "setup",
        components:{
            uploadImage
        },
        data(){
            const validatePhone = (rule, value, callback) => {
                const me = this;
                if (!value) {
                    callback(new Error('请输入手机号码'))
                } else if (!oucy.isValidPhone(value)) {
                    callback(new Error('请输入正确的11位手机号码'))
                } else {

                    oucy.postRequest('/client/user/userauth/vertifyRegisterPhone',{
                        phoneNumber: value
                    }).then(res=>{
                        callback()
                    }).catch(err=>{
                        callback(new Error(err))
                    });
                }
            };
            const validatePhoneCode = (rule, value, callback) => {
                const me = this;
                if(me.isNeedPhoneCode()){
                    if (!value) {
                        callback(new Error('请输入手机验证码'))
                    } else {
                        callback()
                    }
                } else {
                    callback()
                }
            };
            return{
                setupForm:{
                    id:null,
                    userDcodeUsername:null,
                    avata:null,
                    unionId:null,
                    openId:null,
                    phoneNumber:null,
                    phoneCode:null,
                    createTime:null
                },
                localUserInfo:null,
                reBindWechat:false,
                scanUserEntity:null,
                codeTime: 0,
                codeTimer: null,
                showGetCode:true,
                setupFormRules:{
                    userDcodeUsername: [
                        {required: true, message: '请设置登录账号', trigger: 'blur'},
                    ],
                    avata: [
                        {required: true, message: '请设置头像', trigger: 'blur'},
                    ],
                    phoneNumber: [
                        {required: true, validator: validatePhone, trigger: 'change'},
                    ],
                    phoneCode: [
                        {validator: validatePhoneCode, trigger: 'blur'},
                    ],
                    unionId: [
                        {required: true, message: '请设置绑定微信', trigger: 'blur'},
                    ]
                },
                qrCode_1:null,
                qrCode_2:null,
            }
        },
        mounted() {
            this.qrCode_1 = new QRCode(this.$refs.unionIdQrCodeUrl_1, {
                // text: text,
                // text: me.$oucy.baseHost+'/routerView/wechartLogin/'+me.$oucy.scanKeyOfPreLogin+'?p='+me.$oucy.getUUID(),
                width: 146,
                height: 146,
                colorDark: '#000000',
                colorLight: '#ffffff',
                correctLevel: QRCode.CorrectLevel.H
            })
            this.qrCode_2 = new QRCode(this.$refs.unionIdQrCodeUrl_2, {
                // text: text,
                // text: me.$oucy.baseHost+'/routerView/wechartLogin/'+me.$oucy.scanKeyOfPreLogin+'?p='+me.$oucy.getUUID(),
                width: 146,
                height: 146,
                colorDark: '#000000',
                colorLight: '#ffffff',
                correctLevel: QRCode.CorrectLevel.H
            })
            // this.queryUserBindScanAddress()
            const me = this;
            me.reBindWechat = false;
            // me.$nextTick(me.queryUserBindScanAddress);
            oucy.getCodeTime().then(res => {
                me.codeTime = Number(res);
                me.codeTimer = setInterval(() => {
                    if (me.codeTime > 0) {
                        me.showGetCode = false;
                        me.codeTime--;
                    } else {
                        me.showGetCode = true;
                    }
                }, 1000);
            });
            me.eventBus.$off(me.$oucy.msgKeys.QRSCAN_PRE_BIND).$on(me.$oucy.msgKeys.QRSCAN_PRE_BIND,(res)=>{
                console.log('扫码事件：');
                console.log(res);
                me.scanUserEntity = res.ltUserDetailFull;
                me.setupForm.unionId = null;
            }).$off(me.$oucy.msgKeys.QRSCAN_CONFIRM_BIND).$on(me.$oucy.msgKeys.QRSCAN_CONFIRM_BIND, (res)=>{
                console.log('确定事件：');
                console.log(res);
                me.scanUserEntity = res.ltUserDetailFull;
                me.setupForm.unionId = res.userWechatUnicode;
                me.setupForm.openId = res.userWechatOpenidOpenAccount;
            });
            userMaterial.queryUserMaterial().then(res=>{
                console.log(res);
                me.localUserInfo = res;
                me.setupForm.id = res.id;
                me.setupForm.userDcodeUsername = res.userDcodeUsername
                me.setupForm.avata = res?res.detailAvatar:null;
                me.setupForm.phoneNumber = res.userPhoneNumber;
                me.setupForm.createTime = res.userCreatetime;
                me.setupForm.unionId = res.userWechatUnicode;
                me.setupForm.openId = res.userWechatOpenidOpenAccount;
            });

        },
        methods: {
            keywordFiltr(fn){
                this.$oucy.keywordFiltr(this.setupForm).then(res=>{
                   this[fn]() 
                },err=>{})
            },
            handleOnBeforeUploadAndSquare: function(file){
                const me = this;
                return new Promise((resolve, reject) => {
                    me.$oucy.checkImgFile(file).then(res=>{
                        return me.$oucy.checkImgSquare(res, 1, 1);
                    }).then(res=>{
                        resolve(res);
                    }).catch(err=>{
                        reject(err);
                    });
                });

            },
            handleAvataImgUpload: function(params) {
                const me = this;
                const file = params.file;
                me.$oucy.checkImgFile(file).then(res=>{
                    let Oss=new oss()
                    return Oss.startUpload(file)
                    // return me.$oucy.uploadRequest(file);
                }).then(res=>{
                    me.setupForm.avata = res;
                });
            },
            creatQrCode() {
                const me = this;
                let myQrCode_1 = new QRCode(this.$refs.unionIdQrCodeUrl_1, {
                    text: me.$oucy.baseHost+'/routerView/wechartLogin/'+me.$oucy.scanKeyOfPreBind+'?p='+me.$oucy.getUUID()+ "&operationOriginPlatform=0&operationUv="+me.$oucy.getOperationUv(),
                    width: 146,
                    height: 146,
                    colorDark: '#000000',
                    colorLight: '#ffffff',
                    correctLevel: QRCode.CorrectLevel.H
                })
                let myQrCode_2 = new QRCode(this.$refs.unionIdQrCodeUrl_2, {
                    text: me.$oucy.baseHost+'/routerView/wechartLogin/'+me.$oucy.scanKeyOfPreBind+'?p='+me.$oucy.getUUID()+ "&operationOriginPlatform=0&operationUv="+me.$oucy.getOperationUv(),
                    width: 146,
                    height: 146,
                    colorDark: '#000000',
                    colorLight: '#ffffff',
                    correctLevel: QRCode.CorrectLevel.H
                })
            },
            getAccSetupPhoneCode: function () {
                const me = this;
                me.$refs['setupForm'].validateField('phoneNumber', (message) => {
                    if (message == null || message.length === 0) {
                        oucy.postRequest('/client/user/userauth/accSetupSendCode', {
                            phoneNumber: me.setupForm.phoneNumber,
                            uuid: oucy.getUUID()
                        }).then(res => {
                            me.codeTime = Number(res);
                            me.$notify({
                                title: '短信发送成功',
                                message: '验证码已发送，请留意短信通知',
                                type: 'success'
                            });
                        });
                    }
                });
            },
            submitSetup: function () {
                const me = this;
                me.$refs["setupForm"].validate((valid) => {
                    if (valid) {
                        oucy.postRequest('/client/user/userauth/accSetup',{
                            userDcodeUsername:me.setupForm.userDcodeUsername,
                            avata:me.setupForm.avata,
                            phoneNumber:me.setupForm.phoneNumber,
                            phoneCode:me.setupForm.phoneCode,
                            unionId:me.setupForm.unionId,
                            openId:me.setupForm.openId
                        }).then(res=>{
                            oucy.replace('/acc', function () {
                                me.$notify({
                                    title: '操作成功',
                                    message: '你的账号信息已完成设置',
                                    type: 'success'
                                });
                            });
                        });
                    }
                });
            },
            /**
             * 重新扫码
             */
            reScanHandle: function () {
                const me = this;
                me.reBindWechat = true;
                me.scanUserEntity = null;
                me.setupForm.unionId = null;
                me.setupForm.openId = null;
                me.queryUserBindScanAddress()
            },
            /**
             * 取消扫码
             */
            cancelScanHandle: function () {
                const me = this;
                me.reBindWechat = false;
                me.scanUserEntity = null;
                me.setupForm.unionId = me.localUserInfo.userWechatUnicode;
                me.setupForm.openId = me.localUserInfo.userWechatOpenidOpenAccount;
            },
            isNeedPhoneCode: function () {
                const me = this;
                return (!((!!me.setupForm.phoneNumber) && (!!me.localUserInfo) && (me.setupForm.phoneNumber===me.localUserInfo.userPhoneNumber)));
            },
            uploadImageBack(v){
                this.setupForm.avata = v
                console.log(v)
            },
            queryUserBindScanAddress(){
                userAuth.queryUserBindScanAddress({
                    p:this.$oucy.getUUID(),
                    operationOriginPlatform:0,
                    operationUv:this.$oucy.getOperationUv()
                }).then(res=>{
                    this.qrCode_1 && this.qrCode_1.clear();
                    this.qrCode_1.makeCode(res)
                    this.qrCode_2 && this.qrCode_2.clear();
                    this.qrCode_2.makeCode(res)
                    console.log(res)
                })
            },
        },
        beforeDestroy() {
            const me = this;
            me.eventBus.$off(me.$oucy.msgKeys.QRSCAN_PRE_BIND);
            me.eventBus.$off(me.$oucy.msgKeys.QRSCAN_CONFIRM_BIND);
        }
    }
</script>

<style scoped>
.setupForm-container{
    width: 450px;
    padding:50px;
}
.avatar-uploader >>> .el-upload {
    border: 1px #d9d9d9 dashed;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    float: left;
    margin: 0 8px 8px 0;
}
.avatar-uploader >>> .el-upload:hover {
    border-color: #409EFF;
}

.bottom_button .el-button{
    width:143px;
}
/deep/ .el-form-item label:after {
  content: " ";
}

/deep/ .el-form-item__label {
  text-align-last: justify
}
/* 这里去除必选字段的*,这个符号会造成一定影响 */
/deep/ .el-form-item.is-required .el-form-item__label:before {
  content: none !important;
}
</style>
